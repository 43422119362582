<!--
 * @Date: 2021-09-27 15:36:33
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021-09-27 15:36:44
 * @Description:  医工端设置
 * @FilePath: \maintenancef:\sass_web\maintenance_expert\src\views\SystemSettings\userInfo\index.vue
-->
<template>
  <div class='JZContainer'>
    <ContentBlock>
      <el-form label-position="right" ref="JZForm"  :rules="JZFormRules" :model='JZForm'  label-width="150px" class="brand-form ">
        <el-row>
            <el-col :span="12" >
                <el-form-item label="单位名称：" prop='saasName'>
                  <span slot="label">
                    单位名称：
                    <el-tooltip class="item" effect="dark" content="默认为您的企业名称，您可修改为使医院更易识别的名称。" placement="top-start">
                      <i class="el-icon-warning" />
                    </el-tooltip>
                  </span>
                  <el-input maxlength="100" show-word-limit placeholder="请输入单位名称" :readonly="forbid" v-model.trim="JZForm.saasName"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
         <el-row>
            <el-col :span="12" >
                <el-form-item label="单位名称：" prop='unitAbbreviation'>
                  <span slot="label">
                    单位简称：
                  </span>
                  <el-input maxlength="10" show-word-limit placeholder="请输入单位简称"  :readonly="isDisabled" v-model.trim="JZForm.unitAbbreviation"></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="联系电话：" prop="customerPhoneNumber">
                  <span slot="label">
                    联系电话：
                    <el-tooltip class="item" effect="dark" content="请维护您的统一客服联系电话，该信息会展示给您的医院。" placement="top-start">
                      <i class="el-icon-warning" />
                    </el-tooltip>
                  </span>
                    <el-input maxlength="20" show-word-limit placeholder="请输入医院电话" :readonly="isDisabled" v-model.trim="JZForm.customerPhoneNumber" ></el-input>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="联系地址：" prop="contactAddress">
                <el-input maxlength="50" show-word-limit placeholder="请输入联系地址" :readonly="isDisabled" v-model.trim="JZForm.contactAddress" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="公司介绍：" prop="remark">
                <el-input maxlength="500"  type="textarea" :rows="3" show-word-limit placeholder="请输入您的公司名称、主要服务的设备品种、服务类型，及其他证明公司的能力信息等" :readonly="isDisabled" v-model.trim="JZForm.remark" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
              <el-form-item label="品牌LOGO："  prop="sassLogo">
                <span slot="label">
                    品牌LOGO：
                  </span>
                <Upload :disabled="isDisabled"
                  v-model.trim="fileLists"
                  label="品牌LOGO"
                  @success="handleLogoUpload"
                  @remove="handleLogoRemove"
                  :maxSize="1"
                  size="small"
                  :limit="1"/>
              </el-form-item>
            </el-col>
        </el-row>
        <el-row v-if="false">
          <el-col :span="12">
            <el-form-item label="报修网址：" prop="userUrl">
              <el-input  show-word-limit placeholder="请输入报修网址" disabled v-model.trim="JZForm.userUrl" ></el-input>
            </el-form-item>
        </el-col>
        </el-row>
        <el-row v-if="false">
            <el-col :span="24">
                <el-form-item label="报修入口：" prop="saasQrCode">
                  <el-image
                    style="width: 100px; height: 100px;vertical-align: top;margin-right:10px;border:1px solid #efefef;border-radius:5px;"
                    :src="JZForm.saasQrCode"
                    :preview-src-list='saasQrCodeLists'
                    fit="fill">
                  </el-image>
                  <el-link @click="handleDown(JZForm.saasQrCode,`${JZForm.saasName}二维码`)" type="primary">下载二维码</el-link>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12" >
                <el-form-item label="主管理员：" prop='adminId'>
                  <el-select v-model="JZForm.adminId" filterable placeholder="请选择">
                    <el-option
                      v-for="item in adminList"
                      :key="item.adminId"
                      :label="item.adminName"
                      :value="item.adminId">
                    </el-option>
                  </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    </ContentBlock>
    <BottomPanel>
      <slot>
        <!-- <el-button type="primary" plain >返回</el-button> -->
        <el-button type="primary" @click="handleUpdateSaasInfo" v-auth="'system:userInfo:edit'" >保存</el-button>
      </slot>
    </BottomPanel>
  </div>
</template>

<script>
import SystemSetting from '@/api/systemSetting';
import Upload from '@/components/common/upload';
export default {
  name: 'UserInfo',
  components: {
    Upload,
  },
  mounted () {
    let userInfo = JSON.parse(this.$local.get('userInfo')) || {};
    if (['2', '3'].includes(userInfo.authenticationStatus)) {
      this.forbid = true;
    }
    this.getDetailInfo();
  },
  methods: {
    handleDown (imgsrc, name) {
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute('crossOrigin', 'anonymous');
      image.onload = function () {
        var canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL('image/png'); // 得到图片的base64编码数据
        var a = document.createElement('a'); // 生成一个a元素
        var event = new MouseEvent('click'); // 创建一个单击事件
        a.download = name || '二维码'; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    getDetailInfo () {
      SystemSetting.getSassUserInfo({
        id: this.$store.state.User.currentUserInfo.tenantId,
      }).then((res) =>{
        if (res.heads.code === 200) {
          this.fileLists = [];
          res.body.sassLogo && this.fileLists.push({url: res.body.sassLogo});
          res.body.saasQrCode && this.saasQrCodeLists.push(res.body.saasQrCode);
          let currentInfo = res.body;
          this.JZForm.saasName = currentInfo.saasName || '小紫医疗';
          this.JZForm.saasQrCode = currentInfo.saasQrCode || '';
          this.JZForm.sassLogo = currentInfo.sassLogo || '';
          this.JZForm.userUrl = currentInfo.userUrl || '';
          this.JZForm.unitAbbreviation = currentInfo.unitAbbreviation || '';
          this.JZForm.customerPhoneNumber = currentInfo.customerPhoneNumber || '';
          this.JZForm.contactAddress = currentInfo.contactAddress || '';
          this.JZForm.id = currentInfo.id;
          this.JZForm.remark = currentInfo.remark || '';
          this.JZForm.adminId = currentInfo.adminList.find(t=>t.masterFlag)?.adminId;
          this.adminList = currentInfo.adminList;
        }
      });
    },
    handleLogoUpload (file) {
      this.JZForm.sassLogo = file.url;
      this.$refs.JZForm.validate();
    },
    handleLogoRemove () {
      this.JZForm.sassLogo = '';
      this.$refs.JZForm.validate();
    },
    handleUpdateSaasInfo () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          SystemSetting.editUserInfo(this.JZForm).then((res) =>{
            if (res.heads.code === 200) {
              this.$JZShowAlert('医工端设置更新成功！', 'success');
              // this.$router.go(0);
            } else {
              this.$JZShowAlert(res.data.message);
            }
          });
        }
      });
    },
  },
  data () {
    return {
      JZForm: {
        customerPhoneNumber: '',
        saasName: '小紫医疗',
        sassLogo: '',
        saasQrCode: '',
        userUrl: '',
        contactAddress: '',
        id: '',
        remark: '',
        adminId: null,
        unitAbbreviation: '',
      },
      saasQrCodeLists: [],
      fileLists: [],
      JZFormRules: {
        sassLogo: [
          {
            required: true,
            message: '请选择品牌logo',
            trigger: ['blur', 'change'],
          },
        ],
        unitAbbreviation: [{
          max: 10,
          message: '单位简称最多10个字符',
          trigger: ['blur', 'change'],
        }],
        saasQrCode: [
          {
            required: true,
            message: '您的二维码不可为空！',
            trigger: ['blur', 'change'],
          },
        ],
        customerPhoneNumber: [
          {
            // pattern: /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,15}$/,
            pattern: /^([0-9]|[-]){3,20}$/,
            message: '医院电话格式不正确！',
            trigger: ['blur', 'change'],
          },
        ],
        adminId: [
          {
            required: true,
            message: '请设置主管理员！',
            trigger: ['blur', 'change'],
          },
        ],
      },
      forbid: false,
      isDisabled: false,
      adminList: [],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
